<template>
  <AppLayout v-slot="{}">
    <Breadcrumbs />
    <b-container class="checkout-account">
      <div v-if="!thanksActive">
        <!-- <b-row class="justify-content-around">
          <b-col md="5">
            <h1>{{ $t("create_new_account") }}</h1>
            <h2>{{ $t("personal_information") }}</h2>
          </b-col>
          <b-col md="4" class="d-flex align-items-end">
            <h5 class="mb-30">{{ $t("mandatory_fields") }}</h5>
          </b-col>
        </b-row> -->
        <b-form class="checkout-form" @submit.prevent="formSubmit">
          <h1 class="col-md-10 mx-auto">{{ $t("create_new_account") }}</h1>
          <b-row class="justify-content-center">
            <b-col md="6">
              <h2 class="pl-10">{{ $t("personal_information") }}</h2>
              <div class="col-md-8">
                <b-form-group class="account-inputs" id="email-group" :label="$t('form_email')" label-for="email">
                  <b-form-input :placeholder="$t('form_email')" id="email" v-model="form.email" type="text"
                    :state="form.emailState" aria-describedby="email-feedback"></b-form-input>
                  <b-form-invalid-feedback id="email-feedback">
                    {{ form.emailError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="account-inputs" id="password-group" :label="$t('form_password')"
                  label-for="password">
                  <b-form-input :placeholder="$t('form_password')" id="password" v-model="form.password" type="password"
                    :state="form.passwordState" aria-describedby="password-feedback"></b-form-input>
                  <client-only>
                    <password-meter :password="form.password" />
                  </client-only>
                  <b-form-invalid-feedback id="password-feedback">
                    {{ form.passwordError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="account-inputs" id="password_confirm-group"
                  :label="$t('form_password_confirmation')" label-for="password_confirm">
                  <b-form-input :placeholder="$t('form_password_confirmation')" id="password_confirm"
                    v-model="form.password_confirm" type="password" :state="form.password_confirmState"
                    aria-describedby="password_confirm-feedback"></b-form-input>
                  <b-form-invalid-feedback id="password_confirm-feedback">
                    {{ form.password_confirmError }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-checkbox id="checkbox-1" v-model="isCompany" name="checkbox-1">
                  {{ $t("form_are_you_a_company") }}
                </b-form-checkbox>
                <div v-if="isCompany" class="short-form">
                  <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name')"
                    label-for="company">
                    <b-form-input :placeholder="$t('form_company_name')" id="company" v-model="form.company" type="text"
                      v-on:blur="validateCompany()" :state="form.companyState"
                      aria-describedby="company-feedback"></b-form-input>
                    <b-form-invalid-feedback id="company-feedback">
                      {{ form.companyError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group class="account-inputs" id="coc_number-group" :label="$t('form_coc_number')"
                    label-for="coc_number">
                    <b-form-input :placeholder="$t('form_coc_number')" id="coc_number" v-model="form.coc_number"
                      type="text" v-on:blur="validateCOCNumber()" :state="form.coc_numberState"
                      aria-describedby="coc_number-feedback"></b-form-input>
                    <b-form-invalid-feedback id="coc_number-feedback">
                      {{ form.coc_numberError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')"
                    label-for="taxvat">
                    <b-form-input :placeholder="$t('form_vat_number')" id="taxvat" v-model="form.taxvat" type="text"
                      :state="form.taxvatState" aria-describedby="taxvat-feedback"></b-form-input>
                    <b-form-invalid-feedback id="taxvat-feedback">
                      {{ form.taxvatError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </b-col>

            <b-col md="4">
              <h5 class="mandatory_fields pt-2">{{ $t("mandatory_fields") }}</h5>
              <b-form-group class="account-inputs" id="first-name-group" :label="$t('form_first_name')"
                label-for="first_name">
                <b-form-input :placeholder="$t('form_first_name')" id="first_name" v-model="form.firstname" type="text"
                  v-on:blur="validateFirstname()" :state="form.firstnameState"
                  aria-describedby="firstname-feedback"></b-form-input>
                <b-form-invalid-feedback id="firstname-feedback">
                  {{ form.firstnameError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="account-inputs" id="last-name-group" :label="$t('form_last_name')"
                label-for="last_name">
                <b-form-input :placeholder="$t('form_last_name')" id="last_name" v-model="form.lastname" type="text"
                  v-on:blur="validateLastname()" :state="form.lastnameState"
                  aria-describedby="lastname-feedback"></b-form-input>
                <b-form-invalid-feedback id="lastname-feedback">
                  {{ form.lastnameError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="account-inputs" id="country-group" :label="$t('form_country')" label-for="country">
                <b-form-select class="form-control select" id="country" v-model="form.country"
                  :options="countries"></b-form-select>
              </b-form-group>
              <!-- <b-form-group class="account-inputs" :label="$t('address')" label-for="postcode">
                <b-form-input :placeholder="$t('form_password')" id="postcode" v-model="form.country" type="text" :state="form.country"
                  aria-describedby="address.postcode-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.postcode-feedback">
                  {{ form.address }}
                </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group class="account-inputs" id="house_number_and_addition-group"
                :label="$t('form_house_number') + ' + ' + $t('form_addition')" label-for="house_number_and_addition">
                <b-form-input :placeholder="$t('form_house_number') + ' + ' + $t('form_addition')"
                  id="house_number_and_addition" v-model="form.house_number_and_addition" type="text"
                  v-on:blur="validateHouseNumber()" :state="form.house_number_and_additionState"
                  aria-describedby="address.house_number_and_addition-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.house_number_and_addition-feedback">
                  {{ form.house_number_and_additionError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="account-inputs" id="postcode-group1" :label="$t('form_postal_code')"
                label-for="postcode1">
                <b-form-input :placeholder="$t('form_postal_code')" id="postcode1" v-model="form.postcode" type="text"
                  v-on:blur="validatePostcode()" :state="form.postcodeState"
                  aria-describedby="address.postcode1-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.postcode1-feedback">
                  {{ form.postcodeError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="account-inputs" id="ort-group" :label="$t('form_city')" label-for="ort">
                <b-form-input :placeholder="$t('form_city')" id="ort" v-model="form.ort" type="text"
                  v-on:blur="validateOrt()" :state="form.ort" aria-describedby="address.ort-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.ort-feedback">
                  {{ form.ort }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <b-form-group v-if="foundAddress != '' && showFullAddress == false" class="account-inputs" id="add-group"
                label=" " label-for="add">
                <div id="add">
                  {{ foundAddress }}
                </div>
              </b-form-group>
              <b-form-group v-if="foundAddresses.length > 0 && showFullAddress == false" class="account-inputs"
                id="addm-group" label=" " label-for="addm">
                <div id="addm">
                  <b-form-radio style="width: 300px" class="account-radios" v-model="selectedAddress"
                    v-for="(item, index) in foundAddresses" :key="index" name="some-radios" :value="index">{{ item.text
                    }}</b-form-radio>
                </div>
              </b-form-group> -->
              <!-- <b-form-checkbox v-if="showManual" id="manual" v-model="showFullAddress" name="manual">{{
                $t("form_fillout_address_manual") }}</b-form-checkbox> -->
              <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone')" label-for="telephone">
                <b-form-input :placeholder="$t('form_phone')" id="telephone" v-model="form.telephone" type="text"
                  v-on:blur="validatePhone()" :state="form.telephoneState"
                  aria-describedby="address.telephone-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.telephone-feedback">
                  {{ form.telephoneError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-checkbox id="checkbox-2" name="checkbox-2" label-for="checkbox2" v-model="form.default_billing">
                {{ $t("form_address_also_invoice_address") }}
              </b-form-checkbox>
              <b-form-checkbox id="checkbox-3" name="checkbox-3" label-for="checkbox3" v-model="form.is_subscribed">
                {{ $t("form_newsletter_subscription") }}
              </b-form-checkbox>
            </b-col>

            <div class="btn-wrap text-center">
              <b-button type="submit" variant="warning" class="text-white form-submit-btn">
                {{ $t("create_my_account") }}
              </b-button>
            </div>

          </b-row>
        </b-form>
      </div>
      <CreateAccountThanks v-if="thanksActive" />
    </b-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import CreateAccountThanks from "@/base/core/components/authentication/sign-up/CreateAccountThanks";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import config from "@config";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "CreateAccount",
  components: {
    AppLayout,
    Breadcrumbs,
    CreateAccountThanks,
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
    };
  },
  mounted() {
    const bcrumb = { current: this.$t("create_new_account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (typeof config.customers.defaultCountry != "undefined") {
      // this.form.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      // this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },

  data() {
    return {
      isCompany: false,
      thanksActive: false,
      showFullAddress: true,
      // showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: null,
        country: "",
        countryState: null,
        countryError: null,
        // address: {
        //   country_code: "",
        postcode: "",
        postcodeState: null,
        postcodeError: "",
        postcodeTimer: null,

        //   street: "",
        //   streetDisplay: "",
        //   streetDisplayState: null,
        //   streetDisplayError: "",
        //   streetDisplayTimer: null,

        house_number_and_addition: "",
        house_number_and_additionState: null,
        house_number_and_additionError: "",
        //   house_number_and_additionTimer: null,

        //   addition: "",
        //   city: "",
        //   cityState: null,
        //   cityError: "",
        //   cityTimer: null,

        telephone: "",
        telephoneState: null,
        telephoneError: "",
        //   telephoneTimer: null,
        default_billing: true,
        //   default_shipping: true,
        // },
        is_subscribed: true,
        checkbox2: false,
        manual: false,
      },
    };
  },
  computed: {
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.country;
    },
    postcodeHouseNumber() {
      return `${this.form.postcode}|${this.form.house_number_and_addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },

    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
  },
  methods: {
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_already_exists");
        return false;
      }
      this.form.emailState = true;
      return true;
    },
    async formSubmit() {
      //TODO Validation
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }

      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        if (this.form.coc_numberState != true) {
          this.validateCOCNumber();
          if (this.coc_numberState != true) {
            formValid = false;
          }
        }
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.taxvatState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }
      if (this.form.postcodeState != true) {
        this.validatePostcode();
        if (this.form.postcodeState != true) {
          formValid = false;
        }
      }
      if (this.form.house_number_and_additionState != true) {
        this.validateHouseNumber();
        if (this.form.house_number_and_additionState != true) {
          formValid = false;
        }
      }
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      if (this.form.telephoneState != true) {
        this.validatePhone();
        if (this.form.telephoneState != true) {
          formValid = false;
        }
      }
      if (this.form.address.cityState != true) {
        this.validateOrt();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        this.form.address.street = this.form.address.streetDisplay;
        if (this.form.house_number_and_addition != "") {
          this.form.address.street =
            this.form.address.street + " " + this.form.house_number_and_addition;
        }
        if (this.form.address.addition != "") {
          this.form.address.street =
            this.form.address.street + "" + this.form.address.addition;
        }
        if (this.isCompany != true) {
          this.form.company = "";
          this.form.taxvat = "";
          this.form.coc_number = "";
        }
        const retval = await this.$store.dispatch("user/createAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: "account_create",
            text: this.$t("account_created"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/login");
        }
      }
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (this.form.taxvat.substring(0, 2).toUpperCase()) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
        this.form.taxvatError = "";
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber() {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          // this.form.coc_numberState = false;
          // this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
        }
      } else {
        this.form.coc_numberState = null;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.house_number_and_addition == "") {
        this.form.house_number_and_additionState = false;
        this.form.house_number_and_additionError = this.$t("house_number_and_addition_required");
      } else {
        this.form.house_number_and_additionState = true;
      }
    },
    validateOrt() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.telephone == "") {
        this.form.telephoneState = false;
        this.form.telephoneError = this.$t("phone_required");
      } else {
        this.form.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.postcode,
          this.form.house_number_and_addition
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.postcode == "") {
          this.form.postcodeState = false;
          this.form.postcodeError = this.$t("postcode_required");
        } else {
          this.form.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      if (this.form.postcode == "") {
        this.form.postcodeState = false;
        this.form.postcodeError = this.$t("postcode_required");
      } else {
        this.form.postcodeState = true;
      }
    },
  },
};
</script>

<style lang="scss">
.checkout-account {

  .account-inputs {
    input {
      border-color: $orange;
      height: 45px;
      font-size: 14px;
    }

    select {
      border-color: $orange;
      height: 45px;
      border-radius: 6px;
      font-size: 14px;
    }

    label {
      width: unset;
    }
  }

  .custom-select {
    border-color: $orange ;
    height: 45px;
  }

  .account-inputs h1 {
    font-size: 48px;
    font-style: italic;
    font-weight: 700;
  }

  h1 {
    font-size: 48.95px;
    font-style: italic;
    font-weight: 700;
    margin-top: 25px;
    margin-block: 0px;
  }

  h2 {
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    margin-block: 40px;
  }

  .mandatory_fields {
    margin-block: 40px;
  }

  h5 {
    font-size: 21.09px;
    font-weight: 700;
  }

  .checkout-form {
    padding-left: 0px;
  }

  .custom-control-input:checked~.custom-control-label:before {
    background: $orange;
    border-color: $orange;
  }
}
</style>
